import React from "react";
import SwiperCore, { Keyboard, Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CustomImage } from "components/common/blocks/ImgWrapper";

SwiperCore.use([Keyboard, Navigation]);

export const GalleryModal = ({ data, handleClose }) => {
  const { images, activeIndex, photoUrlKey, isFullUrl } = data;
  if (images.length >= 1) {
    return (
      <div className={styles.image_modal}>
        <div className={styles.image_modal__actions}>
          {data.hideClose || (
            <IconButton className="_close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <Swiper
          navigation={{
            nextEl: ".img-swiper-next",
            prevEl: ".img-swiper-prev",
          }}
          keyboard={true}
          initialSlide={activeIndex}
          loop={images.length > 1}
        >
          {images.map((i, index) => (
            <SwiperSlide key={index}>
              <div className={styles.image_modal__item}>
                <CustomImage
                  alt=""
                  isStaticImg={!!isFullUrl}
                  src={isFullUrl ? i : i[photoUrlKey || "img_url"]}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={clsx("swiper_navs", styles.image_modal__navs)}>
          <button className="img-swiper-prev">
            <KeyboardBackspaceIcon />
          </button>
          <button className="img-swiper-next">
            <KeyboardBackspaceIcon className={styles.image_modal__navsNext} />
          </button>
        </div>
      </div>
    );
  }
  return <div className="image_modal"></div>;
};
